<template>
  <ul
    class="border-t-2 border-green-dark w-full h-1/6 flex justify-evenly text-xl text-center z-30 text-white"
  >
    <template v-if="selectedUnit !== undefined">
      <li
        v-if="selectedUnit.data().ownerRegistersTime || !isOwner()"
        class=" block flex-grow bg-green border-r-2 border-green-darkest"
      >
        <router-link
          class=" py-2 flex flex-col items-center justify-center w-full h-full"
          active-class="bg-yellow "
          :to="{ name: 'TimeRegistration' }"
        >
          <span class="flex justify-center items-center">
            <i class="fas fa-stopwatch"></i>
          </span>
        </router-link>
      </li>
    </template>
    <li
      class=" block flex-grow bg-green border-r-2 border-green-darkest relative"
    >
      <router-link
        class=" py-2 flex flex-col items-center justify-center w-full h-full"
        active-class="bg-yellow "
        :to="{ name: 'Requests' }"
      >
        <span class="flex justify-center items-center">
          <i class="fas fa-hand-holding-heart"></i>
        </span>
      </router-link>
      <span
        v-if="numberOfNewRequests !== 0 && (isOwner() || isLeader())"
        class="absolute -right-1 -top-1 w-6 h-6 bg-red-600 rounded-full border-2 border-red-500 border-solid shadow-md"
      >
        <p>{{ numberOfNewRequests }}</p></span
      >
    </li>
    <li
      v-if="false"
      class=" block flex-grow bg-green border-r-2 border-green-darkest"
    >
      <router-link
        class=" py-2 flex flex-col items-center justify-center w-full h-full"
        active-class="bg-yellow "
        :to="{ name: 'Dayplan' }"
      >
        <span class="flex justify-center items-center">
          <i class="fas fa-calendar-day"></i>
        </span>
      </router-link>
    </li>

    <li
      v-if="false"
      class=" block flex-grow bg-green border-r-2 border-green-darkest"
    >
      <router-link
        class=" py-2 flex flex-col items-center justify-center w-full h-full"
        active-class="bg-yellow "
        :to="{ name: 'Planner' }"
      >
        <span class="flex justify-center items-center">
          <i class="fas fa-calendar-week"></i>
        </span>
      </router-link>
    </li>
    <li class=" block flex-grow bg-green border-r-2 border-green-darkest">
      <router-link
        class=" py-2 flex flex-col items-center justify-center w-full h-full"
        active-class="bg-yellow "
        :to="{ name: 'Registrations' }"
      >
        <span class="flex justify-center items-center">
          <i class="fas fa-list"></i>
        </span>
      </router-link>
    </li>
    <li class=" block flex-grow bg-green ">
      <router-link
        class=" py-2 flex flex-col items-center justify-center w-full h-full"
        active-class="bg-yellow "
        :to="{ name: 'Vacations' }"
      >
        <span class="flex justify-center items-center">
          <i class="fas fa-umbrella-beach"></i>
        </span>
      </router-link>
    </li>
  </ul>
</template>

<script>
import { authentication } from "@/global/services/authentication";
import { requests } from "@/global/services/requests";
import { units } from "@/global/services/units";
import { computed, watchEffect } from "vue";

export default {
  name: "mobile-bottom-bar",
  setup() {
    const state = {
      isLeader: authentication.isLeader,
      selectedUnit: units.getSelectedUnit(true),
      requests: requests.getAllRequests(true),
      numberOfNewRequests: computed(() => {
        const newRequests = state.requests.value.filter(
          r => !r.data().accepted && !r.data().deleted && !r.data().declined
        );
        return newRequests.length;
      })
    };

    watchEffect(() => {
      (authentication.isOwner() || authentication.isLeader()) &&
      units.getSelectedUnitEmployees().length ===
        units.getSelectedUnitEmployeesLength() &&
      state.requests.value.length === 0
        ? requests.updateAllRequests()
        : null;
    });
    return {
      ...state,
      isOwner: authentication.isOwner
    };
  }
};
</script>
